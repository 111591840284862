import {useStripeCheckout} from "../hooks/useStripeCheckout";
import React from "react";
import {Spinny} from "./MyProfile/Lesson";

export const OnlineOnlyBuySection = () => {
    const product = {
        id: ONLINE_ONLY_PRODUCT_ID
    }

    const {
        loading,
        redirectToCheckout
    } = useStripeCheckout(product, {
        cancel_url: 'https://www.waxcarvers.com/advent-calendar',
        success_url: 'https://www.waxcarvers.com/advent-calendar'
    })
    return <div className=" mt-10 rounded">
        <div className="w-full text-center py-3 px-4">
            {loading
                ? <Spinny/>
                : <button onClick={redirectToCheckout}
                          className="mt-3 max-w-md mx-auto text-base text-white sm:text-lg md:mt-5
                  md:text-inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md bg-blue-700 hover:bg-blue-500 sm:w-auto">
                    Buy advent calendar €150
                </button>}
        </div>
    </div>
}

export const OnlineAndMaterialBuySection = () => {
    const product = {
        id: MATERIAL_BOX_PRODUCT_ID
    }
    const {
        loading,
        redirectToCheckout
    } = useStripeCheckout(product, {
        cancel_url: 'https://www.waxcarvers.com/advent-calendar',
        success_url: 'https://www.waxcarvers.com/advent-calendar',
        withShipping: true
    })
    return <div className=" mt-10 rounded">
        <div className="w-full text-center py-3 px-4">
            {loading
                ? <Spinny/>
                : <button onClick={redirectToCheckout}
                          className="mt-3 max-w-md mx-auto text-base text-white sm:text-lg md:mt-5
                  md:text-inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md bg-blue-700 hover:bg-blue-500 sm:w-auto">
                    Buy advent calendar €150
                </button>}
        </div>
    </div>
}
