import React from "react";
import Layout from "../components/layout";
import centeredVid from "../content/homepage/centered_video.mp4";
import {
  aboutsection,
  blogsection,
  blurbsection,
  whatsNewSections,
} from "../content/homepage/homepage";
import { Link } from "gatsby";
import { Section } from "../components/Layout/Section";

function Home() {
  return (
    <Layout
      full
      title="Wax Carvers - Online Wax Carving Classes"
      description="Do you want to learn wax carving? You've come to the right place! Here you can find online wax carving classes and learn to make beautiful and fun jewellery."
    >
      <div className="bg-white">
        <main>
          <div className="relative mt-6">
            <div className="relative mt-6">
              <h1 className="text-center text-4xl tracking-tight sm:text-5xl lg:text-6xl mb-6">
                <span className="block text-blue-700">
                  You want to learn
                  <span className="text-blue-700"> wax carving?</span>
                </span>
              </h1>
            </div>
            <div className="absolute inset-x-0 bottom-0 h-3/4"></div>
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              <div className="relative shadow-xl sm:overflow-hidden">
                <div className="absolute inset-0">
                  <video
                    autoPlay
                    muted
                    loop
                    id="myVideo"
                    className="h-full w-full object-cover md:rounded-xl"
                  >
                    <source src={centeredVid} type="video/mp4" />
                  </video>

                  <div className="absolute inset-0 bg-blue-300 mix-blend-multiply md:rounded-xl"></div>
                </div>
                <div className="relative px-4 py-64 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                  <div className="py-32">
                    <p className="max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl"></p>
                  </div>
                </div>
              </div>
              <h2 className="text-center text-4xl tracking-tight sm:text-5xl lg:text-6xl my-10">
                <span className="block text-blue-700">
                  You're in the right place!
                </span>
              </h2>
            </div>
          </div>

          <div className="bg-blue-600  md:mt-4">
            <div className="max-w-4xl mx-auto py-6 px-4 sm:px-4 lg:px-4">
              <p className="text-xl text-white tracking-wide text-left whitespace-pre-wrap">
                {blurbsection.text}
              </p>
              <br />

              <div className="my-6 lg:flex-shrink-0 mx-12">
                <div className="rounded-md text-center">
                  <Link
                    to={blurbsection.link}
                    className="mx-auto bg-white bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-blue-700 whitespace-nowrap"
                  >
                    {blurbsection.linktext}
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="relative pt-4 pb-32 overflow-hidden">
              <div
                aria-hidden="true"
                className="absolute inset-x-0 top-0 h-48"
              ></div>
              <h2 className="text-center text-4xl tracking-tight sm:text-5xl lg:text-6xl my-10">
                <span className="block text-blue-700">What's new?</span>
              </h2>

              {whatsNewSections.map((it) => (
                <Section item={it} key={it.title} />
              ))}
            </div>
          </div>

          <section className="relative bg-white" aria-labelledby="join-heading">
            <div
              className="hidden absolute inset-x-0 h-1/2 bg-blue-gray-50 lg:block"
              aria-hidden="true"
            ></div>
            <div className="max-w-7xl mx-auto  md:bg-transparent lg:px-8">
              <div className="lg:grid lg:grid-cols-12">
                <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
                  <div
                    className="absolute inset-x-0 h-1/2 bg-blue-gray-50 lg:hidden"
                    aria-hidden="true"
                  ></div>
                  <div className="mx-auto sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                    <div className="aspect-w-10 aspect-h-8 sm:aspect-w-2 md:aspect-h-2 lg:aspect-w-1 lg:aspect-h-1">
                      <img
                        className="object-cover object-center md:rounded-3xl shadow-2xl"
                        src={aboutsection.rectphoto}
                        alt="Photo of me, Sandy."
                      />
                    </div>
                  </div>
                </div>

                <div className="relative bg-blue-200 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
                  <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
                    <h2 className="text-3xl text-gray-700" id="join-heading">
                      {aboutsection.title}
                    </h2>
                    <p className="text-lg text-gray-700 whitespace-pre-wrap">
                      {aboutsection.text}
                    </p>
                    <div className="mt-10 lg:flex-shrink-0 mx-12 md:mx-0">
                      <div className="rounded-md text-center md:text-left">
                        <Link
                          className="mx-auto bg-blue-700 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:bg-blue-600"
                          to={aboutsection.link}
                        >
                          {aboutsection.linktext}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="bg-blue-600 mt-12">
            <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
              <p className="text-left text-xl text-white tracking-wide whitespace-pre-wrap">
                {blogsection.text}
              </p>
              <div className="mt-10 lg:flex-shrink-0 mx-12">
                <div className="rounded-md text-center">
                  <Link
                    to={blogsection.link}
                    className="mx-auto bg-white bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-blue-700"
                  >
                    {blogsection.linktext}
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="bg-blue-100 lg:py-5 rounded">
              <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-center">
                <h2 className="text-2xl text-bold tracking-tight text-gray-700 text-center">
                  <span className="block">Always know what I'm up to!</span>
                </h2>
                <div className="lg:mt-0 lg:flex-shrink-0 mx-12 mt-8">
                  <div className="rounded-md text-center">
                    <a
                      href="https://newsletter.waxcarvers.com"
                      aria-label="Join newsletter"
                      className="inline-flex items-center justify-center px-5 py-3 border border-transparent
                         text-base font-medium rounded-md text-white bg-blue-700 hover:bg-indigo-700"
                    >
                      Get weekly emails!
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  );
}

export default Home;
